import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        main: imageSharp(fluid: {
          originalName: {eq: "Fleet-Features-Any-Vehicle.jpg"}
        }) {
          fluid(maxWidth: 740, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ main }) => (
      <SectionWrapper className={styles.container} id={i18n('_hash:different-vehicle-types')}>
        <Grid container
          alignItems='center'>
          <Grid item xs={12} lg={6} xl={6} className={clsx(
              styles.gridItem,
              styles.gridItemLeftEdge
            )}>
            <Typography color='dark' variant='h4' align='left'
              className={styles.boxedDescriptionTitle}>
              {i18n('Connect any vehicle type with plug&play adapter or hardware-free')}
            </Typography>
            <Typography align='left' variant='h5' weight='light' color='dark'
              className={styles.boxedDescription}>
              {i18n('Connect your car, van, trailer or other vehicles to the Fleet service. We have made it easy to get started and take advantage of all relevant features.')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} xl={6} className={clsx(
              styles.gridItem,
              styles.gridItemRightEdge
            )}>
            <div className={clsx(
                styles.imageContainer,
                styles.mainImage
              )}>
              <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                objectFit='contain'
                className={styles.image}
                fluid={main.fluid}
                alt={i18n('Connect your car, van, or trailer')}/>
            </div>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);