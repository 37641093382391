import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Subheader from '../../../components/Header/navmap/fleet';
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Vehicles from './Vehicles';
import Fleet from './Fleet';
import DriverApp from './DriverApp';
import FAQ from './FAQ';
import CallToAction from '../../CallToAction';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.industries}
    subpage={sitemap.fleet.features}
    subheader={Subheader}
    url='_url:fleet-management-features'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Vehicles/>
    <Fleet/>
    <DriverApp/>
    <FAQ/>
    <CallToAction/>
  </Layout>
);