import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { Fade } from 'react-reveal';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './styles.module.sass';

export default  ({
  fluid,
  delay,
  alt,
  layout = {
    md: 4,
    lg: 4,
    xl: 4
  }
}) => (
  <Grid item container {...layout}
    alignContent='flex-end'
    justifyContent='flex-end'
    alignItems='flex-end'
    className={clsx(
      styles.imageContainer,
      styles.imageContainerWide
    )}>
    <Fade delay={delay} bottom>
      <Img objectFit='contain'
        imgStyle={{
          top: '80px',
          bottom: '0px',
          position: 'absolute'
        }}
        alt={alt}
        className={clsx(
          styles.image,
          styles.imageWrap
        )}
        fluid={fluid}/>
    </Fade>
  </Grid>
);